/* You can add global styles to this file, and also import other style files */
body {
   margin: 0;

   div.machine-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: block;

      iframe.machine-iframe {
         width: 100%;
         height: 100%;
      }
   }

   div.restart-container {
      width: 100%;
      padding: 1rem;
      background-color: rgba(250, 250, 250, 1);

      h1 {
         margin: 0;
         font-family: Arial-BoldMT, "Arial Bold", Arial, sans-serif;
         font-weight: lighter;
         color: darkslategray;
      }
   }

   div.insecure-screen {
   }

   div.ctp-button {
      background-color: aqua;
      padding: .5rem 1rem .5rem 1rem;
      cursor: pointer;

      &.ctp-primary {
         color: rgba(255, 255, 255, 1);
         background-color: rgba(237, 173, 0, 1);
         border-width: 1px;
         border-radius: 1px;
         border-color: rgb(255, 255, 255);
         border-style: none;
      }

      &.ctp-standard {
         color: rgb(51, 51, 51);
         background-color: rgb(228, 228, 228);
         border-width: 1px;
         border-radius: 1px;
         border-color: rgb(201, 201, 201);
         border-style: solid;

         &:hover {
            background-color: rgb(213, 213, 213);
         }
      }
   }
   //BEGIN WAIT SPINNER CSS
   .lds-spinner {
      color: official;
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
   }

   .lds-spinner div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;
   }

   .lds-spinner div:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: #007fa3;
   }

   .lds-spinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
   }

   .lds-spinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
   }

   .lds-spinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
   }

   .lds-spinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
   }

   .lds-spinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
   }

   .lds-spinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
   }

   .lds-spinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
   }

   .lds-spinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
   }

   .lds-spinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
   }

   .lds-spinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
   }

   .lds-spinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
   }

   .lds-spinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
   }

   @keyframes lds-spinner {
      0% {
         opacity: 1;
      }

      100% {
         opacity: 0;
      }
   }
   //END WAIT SPINNER CSS

   .home-button-bar {
      div.ctp-button {
         display: inline-block;
         float: right;
         margin: 0.5rem 2rem 0 0;
      }
   }

   div.restart-confirmation-dialog {
      display: grid;
      grid-template-rows: 2rem auto 3rem;
      width: 100%;
      height: 100%;

      .header-row {
      }

      .body-row {
      }

      .footer-row {
         div.ctp-button {
            display: inline-block;
            float: right;
            margin-left: 2rem;
         }
      }
   }

   p.driver-error {
      color: red;
      font-size: large;
   }
}

html, body {
   height: 100%;
}

body {
   margin: 0;
   font-family: Roboto, "Helvetica Neue", sans-serif;
}
